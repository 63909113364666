<template>
  <div class="_full-w d-flex align-center justify-center _bg-default _vh">
    <div class="temp-login white radius-card" id="myDiv">
      <div :style="`height: ${heightBottom}px`">
        <v-btn icon color="purple" to="/"><v-icon>mdi-home</v-icon></v-btn>
      </div>
      <div class="pa-3 d-flex align-center" :style="`height: ${heightTop}px`">
        <div class="_full-w">
          <div class="d-flex justify-center">
            <v-btn color="green" dark fab depressed large
              ><v-icon>mdi-check</v-icon></v-btn
            >
          </div>
          <h1 class="purple--text text-center my-2">Registrasi Berhasil!</h1>
          <p class="purple--text text-center">
            Email verifikasi akan dikirim ke
            <span class="pa-1 purple lighten-5 rounded-xl"> {{ email }} </span
            >,<br />
            silakan cek email Anda secara berkala.
          </p>
          <div class="d-flex justify-center">
            <v-col cols="12" md="6">
              <v-card
                class="pa-3"
                elevation="0"
                color="purple lighten-5 rounded-lg"
              >
                <p class="purple--text text-center mb-1">
                  Belum mendapatkan email verifikasi?
                </p>
                <div class="d-flex justify-center">
                  <v-btn
                    v-if="!loading"
                    color="purple"
                    dark
                    depressed
                    @click="send"
                    >Kirim Ulang verifikasi</v-btn
                  >
                  <v-btn v-if="loading" color="purple" dark depressed>
                    Loading...
                  </v-btn>
                </div>
              </v-card>
            </v-col>
          </div>
        </div>
      </div>
      <div>
        <div
          class="
            __foot
            purple
            lighten-4
            pa-1
            d-flex
            align-center
            justify-center
          "
          :style="`height: ${heightBottom}px`"
        >
          <small class="purple--text ma-0">
            Customer Service :
            <b @click="toEmail" class="__hover">alumnimapropsiugm@gmail.com</b>
          </small>
        </div>
      </div>
      <notifications group="validate" class="mt-2" position="top center" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      heightTop: 0,
      heightBottom: 0,
      loading: false
    };
  },

  mounted() {
    this.email = this.$route.params.email;
    setTimeout(() => this.countingHeight(), 100);
  },
  methods: {
    send() {
      let data = {
        email: this.email
      };
      this.loading = true;
      this.$store
        .dispatch("auth/resend", data)
        .then(data => {
          this.loading = false;
          if (data.status == "success") {
            this.alert("success", data.message);
          } else {
            this.alert("error", data.message);
          }
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },
    toEmail() {
      window.location.href = "mailto:alumnimapropsiugm@gmail.com";
    },
    countingHeight() {
      let height = document.querySelector("#myDiv").offsetHeight;
      this.heightTop = (80 * height) / 100;
      this.heightBottom = (10 * height) / 100;
    },
    alert(type, title) {
      this.$notify({
        group: "validate",
        type: type,
        title: title,
        duration: 5000
      });
    }
  }
};
</script>

<style scoped>
.switch {
  display: none;
}
.temp-form {
  width: 70%;
}
.full-h {
  height: 100%;
}
._vh {
  height: 100vh;
}
.__foot {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.__hover:hover {
  cursor: pointer;
}

@media (max-width: 768px) {
  .switch {
    display: block;
  }
  .enable {
    padding: 10px;
    width: 100%;
    transition: 0.5s;
  }
  .disable {
    display: none;
    transition: 0.5s;
  }
  .temp-form {
    width: 100%;
  }
  .right {
    display: none !important;
  }
  .left {
    display: none !important;
  }
}

@media (max-width: 576px) {
  .switch {
    display: block;
  }
  .enable {
    padding: 10px;
    width: 100%;
    transition: 0.5s;
  }
  .disable {
    display: none;
    transition: 0.5s;
  }
  .temp-form {
    width: 100%;
  }
  .right {
    display: none !important;
  }
  .left {
    display: none !important;
  }
}
</style>
